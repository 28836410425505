import React, { useContext } from 'react';
import { IconContext } from 'react-icons';
import { CiStreamOff, CiStreamOn } from 'react-icons/ci';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Credits from '@components/Credits';
import useCurrentGame from '@hooks/localStorage/useCurrentGame';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import {
  fifaCoach,
  izziLolCoachUrl,
  izziValorantCoachUrl,
} from '@resources/config';
import { CreditsContext } from '@src/contexts/CreditsProvider';
import { GamesContext } from '@src/contexts/GamesProvider';
import useUserSelector from '@src/store/selectors/useUserSelector';
import { FontFamily, MediaQuery } from '@styles/variables';
import { getActiveSubscriptions } from '@utils/subscription.utils';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, { Divider, Item as MenuItem } from 'rc-menu';

import logoIzzi from '../../assets/izzi/izzi-icon.png';
import useGlobal from '../../store';
import Sidebar from '../Sidebar/Sidebar';
import SubscribeButton from '../SubscribeButton';
import Text from '../typography/Text.component';
import UserLogo from '../UserLogo';
import SelectGame from './components/SelectGame';
import { Header, LiveButtonContent, SignInButton, SignUpButton } from './style';

const useStyles = makeStyles(() => ({
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  logo: {
    width: 40,
    cursor: 'pointer',
  },
  gameInformation: {
    display: 'flex',
    alignItems: 'center',

    marginLeft: 40,
  },
  gameInformationText: {
    fontSize: 14,
    fontFamily: FontFamily.POPPINS,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#BFBFBF',

    marginLeft: 13,

    '@media (max-width: 695px)': {
      display: 'none',
    },
  },
  containerInformation: {
    display: 'flex',
    alignItems: 'center',
  },
  containerButton: {
    alignItems: 'center',
    display: 'flex',

    '@media (max-width: 695px)': {
      display: 'none',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    fontSize: 14,
    fontFamily: FontFamily.POPPINS,
    fontStyle: 'normal',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    color: '#BFBFBF',
    cursor: 'pointer',
    margin: '0px 10px',
    marginTop: 20,
  },

  buttonGuias: {
    fontSize: 14,
    fontFamily: FontFamily.POPPINS,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#BFBFBF',
    cursor: 'pointer',
    margin: '0px 30px',
    marginTop: 20,
  },
  dropdownText: {
    fontSize: 14,
    fontFamily: FontFamily.POPPINS,
    fontStyle: 'normal',
    fontWeight: 'normal',
    cursor: 'pointer',
    margin: 5,
  },
  user: {
    width: 40,
    height: 40,
    marginLeft: 40,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  menu: {
    width: 40,

    '@media (min-width: 695px)': {
      display: 'none',
    },
  },
  menuOption: {
    width: '100%',
    height: 3,
    backgroundColor: '#8DFF35',
    margin: '7px 0px',
  },
  clickable: {
    cursor: 'pointer',
  },
  select: {
    background: '#3BE529',
    width: '100%',
    height: 4,
    marginTop: 16,
  },
  notSelected: {
    background: '#26262',
    width: '100%',
    height: 4,
    marginTop: 16,
  },
}));

const ApplicationHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();
  const location = useLocation();
  const { updateUserCredits } = useContext(CreditsContext);
  const { games } = useContext(GamesContext);
  const deskTopDevice = useMediaQuery(MediaQuery.MEDIUM_DESKTOP_UP, {
    noSsr: true,
  });

  const { logOut } = globalActions;
  const { user } = useUserSelector();
  const {
    currentGame,
    isEfootball,
    isLol,
    isFortnite,
    isValorant,
    isFifa,
    isWarzone,
  } = useCurrentGame();
  const activeSubscriptions = getActiveSubscriptions(user);
  const userId = user?._id;

  const hasSubscription =
    activeSubscriptions.filter(
      (sub) => sub.game?.toUpperCase() === currentGame?.toUpperCase()
    ).length > 0;

  const currentGameInfo = games?.find(
    (game) => game.codeName?.toUpperCase() === currentGame?.toUpperCase()
  );

  const handleLogOut = () => {
    updateUserCredits(null);
    logOut(navigate);
  };

  const path = window.location.pathname;

  const isMenuActive = (slugs) => slugs.some((slug) => path.includes(slug));
  const isMenuActiveClass = (slugs) =>
    isMenuActive(slugs) ? classes.select : classes.notSelected;

  const menu = (
    <Menu style={{ width: 140 }} selectable={false}>
      <MenuItem
        key="1"
        className={classes.clickable}
        onClick={() => navigate('/profile')}
      >
        <Text className={classes.dropdownText}>Meu Perfil</Text>
      </MenuItem>
      <Divider />
      <MenuItem
        key="2"
        className={classes.clickable}
        onClick={() => navigate(`/gaming/${currentGame}`)}
      >
        <Text className={classes.dropdownText}>Meus Objetivos</Text>
      </MenuItem>
      <Divider />
      <MenuItem
        key="3"
        className={classes.clickable}
        onClick={() => navigate('/subscriptions')}
      >
        <Text className={classes.dropdownText}>Assinaturas</Text>
      </MenuItem>
      <Divider />
      <MenuItem
        key="4"
        className={classes.clickable}
        onClick={() => navigate('/purchases')}
      >
        <Text className={classes.dropdownText}>Minhas Análises</Text>
      </MenuItem>
      <Divider />
      {user && user.role !== 'PLAYER' && isLol && (
        <>
          <MenuItem
            key="5"
            className={classes.clickable}
            selectable={false}
            onClick={() => navigate('/my-students')}
          >
            <Text className={classes.dropdownText}>Meus Alunos</Text>
          </MenuItem>
          <Divider />
        </>
      )}
      {user && user.role !== 'PLAYER' && (
        <>
          <MenuItem
            key="6"
            className={classes.clickable}
            selectable={false}
            onClick={() => navigate('/my-sales')}
          >
            <Text className={classes.dropdownText}>Minhas Vendas</Text>
          </MenuItem>
          <Divider />
        </>
      )}
      <MenuItem
        key="7"
        className={classes.clickable}
        selectable={false}
        onClick={() => handleLogOut()}
      >
        <Text className={classes.dropdownText}>Sair</Text>
      </MenuItem>
    </Menu>
  );

  const logoRedirect = () => {
    if (user) navigate('/home');
    else navigate('/signin');
  };

  const handleSignup = () => {
    let signupRoute = '/signup';
    if (currentGame) {
      signupRoute = `/signup/${currentGame}`;
    }
    if (location.pathname.split('/')[1]?.toLowerCase() === 'leagues') {
      signupRoute += `?page=leagues/${currentGame}`;
    }
    navigate(signupRoute);
  };

  const handleSignin = () => {
    let signinRoute = '/signin';
    if (currentGame) {
      signinRoute = `/signin/${currentGame}`;
    }
    if (location.pathname.split('/')[1]?.toLowerCase() === 'leagues') {
      signinRoute += `?page=leagues/${currentGame}`;
    }
    navigate(signinRoute);
  };

  return (
    <Header>
      {deskTopDevice ? (
        <div className={classes.sectionHeader}>
          <div className={classes.containerInformation}>
            <img
              role="presentation"
              src={logoIzzi}
              alt="logo Izzi"
              className={classes.logo}
              onClick={logoRedirect}
            />
            {user && (
              <div className={classes.gameInformation}>
                <SelectGame userId={userId} />
              </div>
            )}
          </div>
          {user ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className={classes.buttons}>
                <Text className={classes.button} component={Link} to="/home">
                  Home
                  <div className={isMenuActiveClass(['home'])} />
                </Text>

                {(isValorant || isLol) && (
                  <Text
                    className={classes.button}
                    component={Link}
                    to={`/analysis/${currentGame}/${userId}`}
                  >
                    Evolução
                    <div className={isMenuActiveClass(['analysis'])} />
                  </Text>
                )}

                {(isValorant || isWarzone) && (
                  <Text
                    className={classes.button}
                    component={Link}
                    to={`/leagues/${currentGame.toUpperCase()}`}
                  >
                    Torneios
                    <div className={isMenuActiveClass(['league'])} />
                  </Text>
                )}

                <Text className={classes.button} component={Link} to="/classes">
                  {isFifa && <>Elite Milionária</>}
                  {isWarzone && <>Conteúdos 9-OPS</>}
                  {isValorant ? <>Matrix Valorant</> : <></>}
                  {isEfootball ? <>eGol Academy</> : <></>}
                  {!isFifa && !isValorant && !isWarzone && !isEfootball ? (
                    <>Cursos</>
                  ) : (
                    <></>
                  )}
                  <div className={isMenuActiveClass(['classes'])} />
                </Text>

                {(isWarzone || isFifa) && (
                  <Text className={classes.button} component={Link} to="/live">
                    <LiveButtonContent>
                      Aula ao Vivo
                      {currentGameInfo?.live?.on ? (
                        <IconContext.Provider
                          value={{ color: '#3BE529', size: '21px' }}
                        >
                          <CiStreamOn />
                        </IconContext.Provider>
                      ) : (
                        <IconContext.Provider value={{ size: '21px' }}>
                          <CiStreamOff />
                        </IconContext.Provider>
                      )}
                    </LiveButtonContent>

                    <div className={isMenuActiveClass(['live'])} />
                  </Text>
                )}

                {!isFortnite && !isWarzone && !isEfootball && (
                  <Text
                    className={classes.button}
                    component={Link}
                    to="/library"
                  >
                    Biblioteca
                    <div className={isMenuActiveClass(['library'])} />
                  </Text>
                )}
                {isValorant && (
                  <Text
                    className={classes.button}
                    component={Link}
                    to={izziValorantCoachUrl}
                  >
                    Análise de Gameplay
                    <div className={isMenuActiveClass(['coaches'])} />
                  </Text>
                )}
                {isLol && (
                  <Text
                    className={classes.button}
                    component={Link}
                    to={izziLolCoachUrl}
                  >
                    Análise de Gameplay
                    <div className={isMenuActiveClass(['coaches'])} />
                  </Text>
                )}
                {isFifa && (
                  <Text
                    className={classes.button}
                    component={Link}
                    to={fifaCoach}
                  >
                    Análise de Times
                    <div className={isMenuActiveClass(['coaches'])} />
                  </Text>
                )}
              </div>
              {!hasSubscription && (
                <SubscribeButton left right currentGame={currentGame} />
              )}

              <Credits />

              <Dropdown trigger={['click']} overlay={menu} animation="slide-up">
                <UserLogo classes={classes.user} />
              </Dropdown>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: { xs: 'none', lg: 'flex' },
                  alignItems: 'center',
                }}
              >
                <SignUpButton onClick={handleSignup}>
                  Cadastre-se na IZZI
                </SignUpButton>
                <SignInButton type="button" onClick={handleSignin}>
                  Entrar
                </SignInButton>
              </Box>
            </>
          )}
        </div>
      ) : (
        <>
          <div className={classes.sectionHeader}>
            <div className={classes.containerInformation}>
              <img
                role="presentation"
                src={logoIzzi}
                alt="logo Izzi"
                className={classes.logo}
                onClick={logoRedirect}
              />
              {user && (
                <div className={classes.gameInformation}>
                  <SelectGame userId={userId} />
                </div>
              )}
            </div>
          </div>
          <Box sx={{ display: 'block' }}>
            <Sidebar
              user={user}
              hasSubscription={hasSubscription}
              currentGame={currentGame}
            />
          </Box>
        </>
      )}
    </Header>
  );
};

export default ApplicationHeader;
