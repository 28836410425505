import useUserSelector from '@src/store/selectors/useUserSelector';

import {
  pageHotmartFifa,
  pageHotmartFortnite,
  pageHotmartLol,
  pageHotmartValorant,
  pageHotmartWarzone,
} from '../resources/config';
import useCurrentGame from './localStorage/useCurrentGame';

export default () => {
  const { isValorant, isFortnite, isLol, isFifa, isWarzone } = useCurrentGame();
  const { user } = useUserSelector();

  const redirectToHotmart = (medium, campaign, game) => {
    const userQueryString = user ? `&email=${user.profile.email}` : '';

    if ((isValorant && !game) || game?.toUpperCase() === 'VALORANT') {
      window.open(
        `${pageHotmartValorant}&utm_medium=${medium}&utm_campaign=${campaign}${userQueryString}`,
        '_blank'
      );
    }

    if ((isFortnite && !game) || game?.toUpperCase() === 'FORTNITE') {
      window.open(
        `${pageHotmartFortnite}&utm_medium=${medium}&utm_campaign=${campaign}${userQueryString}`,
        '_blank'
      );
    }

    if ((isLol && !game) || game?.toUpperCase() === 'LOL') {
      window.open(
        `${pageHotmartLol}&utm_medium=${medium}&utm_campaign=${campaign}${userQueryString}`,
        '_blank'
      );
    }

    if ((isWarzone && !game) || game?.toUpperCase() === 'WARZONE') {
      window.open(
        `${pageHotmartWarzone}&utm_medium=${medium}&utm_campaign=${campaign}${userQueryString}`,
        '_blank'
      );
    }

    if ((isFifa && !game) || game?.toUpperCase() === 'FIFA') {
      window.open(
        `${pageHotmartFifa}&utm_medium=${medium}&utm_campaign=${campaign}${userQueryString}`,
        '_blank'
      );
    }
  };

  return { redirectToHotmart };
};
