import { useContext } from 'react';

import {
  efootballDiscord,
  fortniteDiscord,
  izziDiscord,
  warzoneDiscord,
} from '@resources/config';
import { GamesContext } from '@src/contexts/GamesProvider';

import { version } from '../../../package.json';
import { gamesEnum } from '../../utils/games.utils';

export default () => {
  const { updateCurrentGame } = useContext(GamesContext);
  const izziVersion = `izzi-gaming-${version}`;
  const currentGame = localStorage.getItem(`${izziVersion}-current-game`);
  updateCurrentGame(currentGame);
  const isLol = currentGame === gamesEnum.lol;
  const isValorant = currentGame === gamesEnum.valorant;
  const isFortnite = currentGame === gamesEnum.fortnite;
  const isFifa = currentGame === gamesEnum.fifa;
  const isWarzone = currentGame === gamesEnum.warzone;
  const isEfootball = currentGame === gamesEnum.efootball;
  let discordCurrentGame = izziDiscord;

  if (isFortnite) discordCurrentGame = fortniteDiscord;
  if (isWarzone) discordCurrentGame = warzoneDiscord;
  if (isEfootball) discordCurrentGame = efootballDiscord;

  const setCurrentGame = (gameName) => {
    if (gamesEnum[gameName?.toLowerCase()]) {
      localStorage.setItem(
        `${izziVersion}-current-game`,
        gameName.toUpperCase()
      );
      updateCurrentGame(gameName.toUpperCase());
    }
  };

  return {
    isLol,
    isValorant,
    isFortnite,
    isFifa,
    isWarzone,
    isEfootball,
    currentGame,
    setCurrentGame,
    discordCurrentGame,
  };
};
